<template>
  <v-hover
    v-slot="{ hover }"
  >
    <v-card
      class="mx-auto"
      outlined
      :elevation="hover ? 6 : 2"
      :ripple="false"
      @click.stop="openOverview"
    >
      <v-card-title
        class="truncate align-center"
      >
        {{ caseData.caseName }}
      </v-card-title>
      <div>
        <v-menu
          offset-y
          @click.native.stop.prevent
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              outlined
              dark
              x-small
              class="position-menu"
              v-bind="attrs"
              v-on="on"
              @click.native.stop.prevent
            >
              {{ $t('cases|status') }}
              <v-icon class="ml-1">
                {{ icons.arrowDown }}
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="status in caseStatuses"
              :key="status.value"
              @click.stop="changeStatus(caseData, status)"
            >
              <v-icon
                class="ml-n2 mr-2 mt-1"
                size="18"
              >
                {{ status.icon }}
              </v-icon>
              <v-list-item-title>{{ getTranslatedStatus(status.text) }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-spacer />
        <v-btn
          v-if="caseData.pinned.includes(account._id)"
          icon
          class="position-pin"
          @click.stop="removePinFromDashboard(caseData)"
        >
          <v-icon>
            mdi-pin
          </v-icon>
        </v-btn>
        <v-btn
          v-else
          icon
          class="position-pin"
          @click.stop="pinToDashboard(caseData)"
        >
          <v-icon>
            mdi-pin-outline
          </v-icon>
        </v-btn>
      </div>
      <v-card-text style="min-height: 50px">
        <div>
          {{ $t('clients|client_name') }}:
          <template v-if="caseData && caseData.client">
            {{ getClientName(caseData.client) }}
          </template>
        </div>
        <div>
          {{ $t('common|date_created') }}: {{ caseData.createdAt | date }}
        </div>
        <div>
          {{ $t('common|date_updated') }}: {{ caseData.updatedAt | dateTime }}
        </div>
        <div>
          {{ $t('common|created_by') }}: {{ getCreator(caseData) }}
        </div>
        <div
          class="mx-auto my-auto row"
        >
          {{ $t('cases|participants') }}:
          <div
            v-for="(member, index) in sharedWithMembers"
            :key="index"
            class="ml-1"
          >
            <v-chip
              :id="`memberChip-${index}`"
              x-small
              dense
              outlined
              color="primary"
            >
              {{ member }}
            </v-chip>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="primary"
          outlined
          rounded
          small
          @click.stop.native="routeToCase(caseData)"
        >
          <v-icon
            small
            class="mr-1"
          >
            mdi-arrow-top-right
          </v-icon>
          {{ $t('actions|open') }}
        </v-btn>
        <v-spacer />
        <v-btn
          text
          icon
          @click.stop="getCaseSharedWithLength(caseData) <= 1 ? deleteCase(caseData) : removeCase(caseData)"
        >
          <v-icon>
            {{ icons.trash }}
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-hover>
</template>

<script>
import { caseStatuses } from '@/utils/index'
import CaseCardMixin from './CaseMixins/CaseCardMixin'
import { mdiTrashCanOutline, mdiChevronDown, mdiPinOutline } from '@mdi/js'

export default {
  mixins: [CaseCardMixin],
  data () {
    return {
      icons: {
        trash: mdiTrashCanOutline,
        arrowDown: mdiChevronDown,
        pinOutline: mdiPinOutline
      },
      caseStatuses
    }
  }
}
</script>

<style scoped>
.truncate {
  max-width: 60%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
@media only screen
  and (min-width: 320px)
  and (max-width: 1280px) {
  .truncate {
    max-width: 45%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
.truncate:hover {
  overflow: visible;
  white-space: normal;
}
.position-pin {
  position: absolute;
  top: 15px;
  right: 15px;
}
.position-menu {
  position: absolute;
  top: 22px;
  right: 50px;
}
</style>
